<template>
  <div class="unprocessedCommunications">
    <a-table rowKey="id" :columns="unprocessedCommunicationsColumns" :data-source="unprocessedCommunications" :pagination="false">
      
      <template #type="{ record }">
        <a-tooltip placement="right">
          <template #title>
            <span>{{ record.type }}</span>
          </template>
          <a class="icons" v-if="record.type == 'Email'">
            <MailOutlined />
          </a>
        </a-tooltip>
        <a-tooltip placement="right">
          <template #title>
            <span>{{ record.type }}</span>
          </template>
          <a class="icons" v-if="record.type == 'SMS'">
            <MessageOutlined />
          </a>
        </a-tooltip>
      </template>

      <template #actions="{record}">
        <a-tooltip placement="right">
          <template #title>
            View
          </template>
          <a class="icons" @click="viewMessage(record.id, record.type)">
            <EyeOutlined />
          </a>
        </a-tooltip>
        <a-tooltip placement="right">
          <template #title>
            Assign
          </template>
          <a class="icons" @click="assignToStaff(record.id, record.type)">
            <UserAddOutlined />
          </a>
        </a-tooltip>
      </template>

    </a-table>
  </div>
  
  <UnprocessedCommunicationDetailsModal v-model:visible="visibleViewModal" @closeModal="closeModal" />
  <AssignUnprocessedCommunicationModal v-model:visible="visibleAssignModal" :isUnprocessed="true" @closeModal="closeModal" @is-visible="closeModal" />

</template>

<script>
import { computed, onMounted, ref } from 'vue-demi'
import {
  MailOutlined,
  MessageOutlined,
  EyeOutlined,
  UserAddOutlined,
} from "@ant-design/icons-vue";
import { useStore } from 'vuex';
import UnprocessedCommunicationDetailsModal from "@/components/modals/UnprocessedCommunicationDetailsModal"
import AssignUnprocessedCommunicationModal from "@/components/modals/AssignUnprocessedCommunicationModal"

export default {
  components: {
    MailOutlined,
    MessageOutlined,
    EyeOutlined,
    UserAddOutlined,
    UnprocessedCommunicationDetailsModal,
    AssignUnprocessedCommunicationModal,
  },
  setup() {
    const store = useStore()
    const communicationType = ref(null)
    const visibleViewModal = ref(false)
    const visibleAssignModal = ref(false)

    onMounted(() => {
      store.dispatch('unprocessedCommunications')
    })

    const unprocessedCommunicationsColumns = [
      {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
      },
      {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        slots: {
          customRender: 'type'
        }
      },
      {
        title: 'Date & Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        sorter: true,
        slots: {
          customRender: 'actions'
        }
      },
    ]

    const unprocessedCommunications = computed(() => {
      return store.state.unprocessedCommunications.unprocessedCommunications
    })

    const viewMessage = (id, type) => {
      console.log('viewMessage', id)
      communicationType.value = type
      unprocessedCommunications.value.map(item => {
        if(item.id == id) {
          store.commit('communicationDetails', item)
        }
      })
      visibleViewModal.value = true
    }

    const assignToStaff = (id, type) => {
      console.log('assignToStaff', id)
      communicationType.value = type
      unprocessedCommunications.value.map(item => {
        if(item.id == id) {
          store.commit('communicationDetails', item)
        }
      })
      setTimeout(() => {
        visibleAssignModal.value = true
      }, 1000);
    }

    const closeModal = (value) => {
      visibleViewModal.value = false
      visibleAssignModal.value = value ? value : false
    }

    return {
      unprocessedCommunicationsColumns,
      unprocessedCommunications,
      viewMessage,
      assignToStaff,
      communicationType,
      closeModal,
      visibleViewModal,
      visibleAssignModal,
    }
  }
}
</script>

<style>
	.unprocessedCommunications .anticon-mail svg,
  .unprocessedCommunications .anticon-message svg {
    fill: #000000 !important;
  }
</style>