<template>
<div>
  <a-layout>
    <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
      <Header />
    </a-layout-header>
    <a-layout>
      <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
        <Sidebar />
      </a-layout-sider>
      <a-layout-content>
        <a-row>
          <a-col :span="24">
            <h2 class="pageTittle">Unprocessed Communications</h2>
          </a-col>
          <a-col :span="24">
            <UnprocessedCommunicationsTable />
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>
  </a-layout>
</div>
</template>

<script>
import Header from "../layout/header/Header"
import Sidebar from "../layout/sidebar/Sidebar"
import UnprocessedCommunicationsTable from "@/components/unprocessed-communications/tables/UnprocessedCommunicationsTable"

export default {
  components: {
    Header,
    Sidebar,
    UnprocessedCommunicationsTable,
  }
  
}
</script>